<template>
  <div class="email-template-link-section default-email-message-template__section">
    <slot name="prepend"></slot>
    <img class="default-email-message-template__button" :src="linkName" alt="button-link" />
  </div>
</template>

<script>
export default {
  name: 'EmailMessageTemplateLinkSection',
  props: {
    linkName: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';

.email-template-link-section {
  background: #fffbf8;
}
</style>
