<template>
  <div class="email-template-signature-section default-email-message-template__section">
    <div class="email-template-signature-section__complimentary-close mb10">
      {{ bestSkinLabel }}
      <br />
      {{ yourDoctorLabel }} & skincare<b>activist</b>
    </div>

    <span
      class="email-template-signature-section__signature default-email-message-template__paragraph"
    >
      {{ doctorFullName }}
    </span>

    <div
      class="email-template-signature-section__footer signature-footer flex-column--align-center"
    >
      <img
        class="signature-footer__logo mb5"
        src="@/assets/images/auth/universkinLogo.png"
        alt="Logo"
      />
      <span class="signature-footer__caption">powered by univer<b>skin</b></span>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

import { types } from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

const DEFAULT_BEST_SKIN_LABEL = 'Best skin,';
const DEFAULT_YOUR_DOCTOR_LABEL = 'your doctor';

export default {
  name: 'EmailMessageTemplateSignatureSection',
  props: {
    templateData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      doctorFullName: types.getters.USER_FULL_NAME,
      currentMessageTemplate: dashTypes.getters.CURRENT_MESSAGE_TEMPLATE
    }),
    bestSkinLabel() {
      return get(this.templateData, 'bestSkinLabel') || DEFAULT_BEST_SKIN_LABEL;
    },
    yourDoctorLabel() {
      return get(this.templateData, 'yourDoctorLabel') || DEFAULT_YOUR_DOCTOR_LABEL;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/message-templates/email-message-templates';

.email-template-signature-section {
  border-top: 1px solid $westart-color;

  &__complimentary-close {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
  }

  &__signature:last-of-type {
    padding: 0 5px;
    margin-bottom: 30px;

    font-family: var(--u-font-family-Quentin);
    font-size: 36px;
    line-height: 53px;
    font-weight: normal;
  }

  &__footer {
    border-top: 1px solid $westart-color;
    padding-top: 20px;
  }
}

.signature-footer {
  &__logo {
    width: 32px;
  }

  &__caption {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: $tuatara-color;
  }
}

@media (max-width: 767px) {
  .email-template-signature-section {
    &__complimentary-close {
      font-size: 13px;
      line-height: 20px;
    }

    &__signature:last-of-type {
      margin-bottom: 20px;

      font-size: 24px;
      line-height: 35px;
    }
  }
}
</style>
