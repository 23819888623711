<template>
  <div class="template__message mb15">
    <div class="template__label">{{ $t('dashboard.label.message') }}</div>
    <div class="message__input input-focus-within">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultTemplateMessage'
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/message-templates';
</style>
