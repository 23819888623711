<template>
  <textarea-autosize
    v-validate="{ required }"
    :name="fieldName"
    :value="value"
    :maxlength="maxlength"
    :min-height="25"
    :rows="1"
    class="template__input message__textarea pointer"
    @input.native="onInput"
    @change.native="onChange($event.target.value)"
  />
</template>

<script>
export default {
  name: 'TemplateTextArea',
  inject: ['$validator'],
  props: {
    fieldName: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', { fieldName: this.fieldName, event });
    },
    onChange(value) {
      this.$emit('change', { fieldName: this.fieldName, value });
    }
  }
};
</script>
