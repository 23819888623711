import Splitter from 'split-sms';

const updateTemplateFieldMixin = {
  methods: {
    updateLengthProperty({ fieldName, event, isSmsCampaign }) {
      if (!isSmsCampaign) {
        return;
      }

      const value = event ? event.target.value : '';

      this.lengthData[fieldName] = value;
    },
    getCanInputFieldText({ fieldName, fieldValue }) {
      const newText = this.buildTemplateTextWithField({ fieldName, fieldValue });
      const { parts } = Splitter.split(newText);

      return parts.length < 6;
    },
    async updateTemplateField({
      fieldName,
      event,
      isTextarea = false,
      refName,
      isSmsCampaign = false
    }) {
      const { value } = event.target;
      const canContinue = this.getCanInputFieldText({ fieldName, fieldValue: value });

      if (!isSmsCampaign || canContinue) {
        this.templateData = { ...this.templateData, [fieldName]: value };
        this.updateLengthProperty({ fieldName, event, isSmsCampaign });

        return;
      }

      if (!isTextarea) {
        this.$refs[refName].value = this.templateData[fieldName];

        return;
      }

      const templateValue = this.templateData[fieldName];

      this.templateData = { ...this.templateData, [fieldName]: `${templateValue} ` };

      await this.$nextTick();

      this.templateData = { ...this.templateData, [fieldName]: templateValue };
    }
  }
};

export default updateTemplateFieldMixin;
