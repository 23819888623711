<template>
  <template-text-area
    :ref="fieldName"
    class="email-message-template-textarea"
    :value="value"
    :maxlength="maxLength"
    :field-name="fieldName"
    required
    @input="
      onNotificationTextAreaFieldInput({
        ...$event,
        isTextarea: true,
        refName: fieldName,
        isSmsCampaign
      })
    "
    @change="onNotificationTextAreaFieldChange"
  />
</template>

<script>
import TemplateTextArea from '@/modules/dashboard/components/message-templates/TemplateTextArea';

export default {
  name: 'EmailMessageTemplateTextArea',
  components: { TemplateTextArea },
  props: {
    value: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      required: true
    },
    isSmsCampaign: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onNotificationTextAreaFieldInput(value) {
      this.$emit('field-input', value);
    },
    onNotificationTextAreaFieldChange(value) {
      this.$emit('field-change', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/css/common/errors';

.email-message-template-textarea {
  background: transparent;
}
</style>
