<template>
  <div class="email-message-template-textareas">
    <template-text-area
      v-for="textarea in textareaBindings"
      :ref="textarea.fieldName"
      :key="textarea.fieldName"
      class="email-message-template-textareas__paragraph default-email-message-template__paragraph"
      :class="{ 'error-input-border': getIsBodyPartOneFieldError(textarea.fieldName) }"
      :value="textarea.value"
      :maxlength="textarea.maxLength"
      :field-name="textarea.fieldName"
      required
      @input="
        onTextareaFieldInput({
          ...$event,
          isTextarea: true,
          refName: textarea.fieldName,
          isSmsCampaign
        })
      "
      @change="onTextareaFieldChange"
    />
  </div>
</template>

<script>
import TemplateTextArea from '@/modules/dashboard/components/message-templates/TemplateTextArea';

export default {
  name: 'EmailMessageTemplateTextAreas',
  components: { TemplateTextArea },
  props: {
    textareaBindings: {
      type: Array,
      required: true
    },
    isSmsCampaign: {
      type: Boolean,
      default: false
    },
    bodyError: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTextareaFieldInput(value) {
      this.$emit('field-input', value);
    },
    onTextareaFieldChange(value) {
      this.$emit('field-change', value);
    },
    getIsBodyPartOneFieldError(fieldName) {
      return this.bodyError && fieldName === 'bodyPartOne';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/message-templates/email-message-templates';
@import '../../../../../assets/css/common/errors';

.email-message-template-textareas {
  &__paragraph {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
